import { vShow as _vShow, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60f47f0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-content"
}
const _hoisted_2 = {
  key: 1,
  class: "modal-content"
}
const _hoisted_3 = {
  key: 2,
  class: "modal-content"
}
const _hoisted_4 = {
  key: 3,
  class: "modal-content"
}
const _hoisted_5 = {
  key: 4,
  style: {"padding":"40px"},
  class: "modal-content"
}
const _hoisted_6 = {
  key: 5,
  style: {"padding":"40px"},
  class: "modal-content"
}
const _hoisted_7 = {
  key: 6,
  style: {"padding":"40px"},
  class: "modal-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModal = _resolveComponent("DeleteModal")!
  const _component_RequirementsModal = _resolveComponent("RequirementsModal")!
  const _component_PortMappingModal = _resolveComponent("PortMappingModal")!
  const _component_ReadOnlyDataModal = _resolveComponent("ReadOnlyDataModal")!
  const _component_NewDataProductModal = _resolveComponent("NewDataProductModal")!
  const _component_NewBenchmarkFromGeekbench = _resolveComponent("NewBenchmarkFromGeekbench")!
  const _component_UserEditModal = _resolveComponent("UserEditModal")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "in-out"
    }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args))),
          key: 'backdrop',
          class: _normalizeClass([{'blur': _ctx.modalStore.show}, "backdrop"])
        }, null, 2)), [
          [_vShow, _ctx.modalStore.show]
        ])
      ]),
      _: 1
    }),
    _createVNode(_TransitionGroup, { name: "scale" }, {
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 'modal',
          class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "aeros-modal"]),
          style: _normalizeStyle(_ctx.modalStore?.styles)
        }, [
          (_ctx.modalStore.template === 'delete')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_DeleteModal, {
                  onBack: _ctx.hideModal,
                  onConfirm: _cache[1] || (_cache[1] = (data) => _ctx.modalStore?.data?.onConfirm(data)),
                  data: _ctx.modalStore.data,
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["onBack", "data", "class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.modalStore.template === 'requirements')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_RequirementsModal, {
                  onBack: _ctx.hideModal,
                  onConfirm: _cache[2] || (_cache[2] = (result) => _ctx.confirmData(result)),
                  data: _ctx.modalStore.data,
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["onBack", "data", "class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.modalStore.template === 'portMapping')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_PortMappingModal, {
                  onBack: _ctx.hideModal,
                  onConfirm: _cache[3] || (_cache[3] = (portMapping) => _ctx.confirmData(portMapping)),
                  data: _ctx.modalStore.data,
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["onBack", "data", "class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.modalStore.template === 'ReadOnly')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ReadOnlyDataModal, {
                  data: _ctx.modalStore.data,
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["data", "class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.modalStore.template === 'NewDataProduct')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_NewDataProductModal, {
                  onBack: _ctx.hideModal,
                  onConfirm: _cache[4] || (_cache[4] = (newProduct) => _ctx.modalStore?.data?.onConfirm(newProduct)),
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["onBack", "class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.modalStore.template === 'GeekbenchBenchmark')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_NewBenchmarkFromGeekbench, {
                  onBack: _ctx.hideModal,
                  onConfirm: _cache[5] || (_cache[5] = (newProduct) => _ctx.modalStore?.data?.onConfirm(newProduct)),
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["onBack", "class"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.modalStore.template === 'UserEdit')
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_UserEditModal, {
                  onBack: _ctx.hideModal,
                  user: _ctx.modalStore.data,
                  onConfirm: _cache[6] || (_cache[6] = (editUser) => _ctx.modalStore?.data?.onConfirm(editUser)),
                  class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
                }, null, 8, ["onBack", "user", "class"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("i", {
            class: "close",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'xmark'] })
          ])
        ], 6)), [
          [_vShow, _ctx.modalStore.show]
        ])
      ]),
      _: 1
    })
  ], 64))
}